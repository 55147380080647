import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const header_image_mobile = require("../../../assets/img/blogs/ult_guide_blog_header_mob.png");
const header_image = require("../../../assets/img/blogs/ult_guide_blog_header.png");
const section_1 = require("../../../assets/img/blogs/ult_guide_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/ult_guide_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/ult_guide_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/ult_guide_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/ult_guide_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/ult_guide_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/ult_guide_blog_img_7.png");
const section_8 = require("../../../assets/img/blogs/ult_guide_blog_img_8.png");
const section_9 = require("../../../assets/img/blogs/ult_guide_blog_img_9.png");
const section_10 = require("../../../assets/img/blogs/ult_guide_blog_img_10.png");
const section_11 = require("../../../assets/img/blogs/ult_guide_blog_img_11.png");
const section_12 = require("../../../assets/img/blogs/ult_guide_blog_img_12.png");
const section_13 = require("../../../assets/img/blogs/ult_guide_blog_img_13.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });

  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="The Essential Guide to Transforming your Service Desk"
        description="Workativ’s Generative AI augments automation for service desks, helping them transform and boost employee productivity, efficiency, and experience.         "
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt ult_guide_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-section-bold-cta-blog  color-black line-height-18">
                    The ultimate guide to transforming your service desk with
                    Workativ’s Generative AI
                  </h1>
                ) : (
                  <h1 className="font-page-header-blog-gen-ai color-black">
                    The ultimate guide to
                    <br /> transforming your service
                    <br /> desk with Workativ’s
                    <br /> Generative AI
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What do you mean by service desk transformation?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. What is Generative AI for the service desk?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Why do you need Generative AI for service desks?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. How does Generative AI help the service desk transform?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Introducing Workativ’s Generative AI: The ultimate solution
                  for service desk transformation
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. What can you do with Workativ’s Generative AI?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. The Advantages of Workativ’s Generative AI
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section8"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section8"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section8")}
                >
                  8. How to get started with Workativ’s Generative AI
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section9"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section9"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section9")}
                >
                  9. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                The big truth about{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  service desks
                </a>{" "}
                is that they are, in some way, incapable of managing the entire
                workflow needs.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Automation makes things easier, yet it encompasses a traditional
                approach.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Shift-left is ineffective in many scenarios, and SLAs rarely
                transition to XLAs. IT support is reactive rather than
                proactive.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The overall impact is a productivity decline, employee
                dissatisfaction, agent burnout, and attrition.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For IT leaders,
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Generative AI is a boon to transform the existing state of
                  service desks.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The{" "}
                <a href="https://www.nber.org/digest/20236/measuring-productivity-impact-generative-ai">
                  National Bureau of Economic Research (NBER)
                </a>{" "}
                studied productivity gains using Generative AI. They claimed
                that the pre-trained generative transformer (GPT), the
                underlying deep learning technology of Generative AI, can help
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  boost customer support worker productivity by 14%.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As per the study, if any role needs information to do a job,
                Generative AI can speed up their ability to find information
                quickly and gain success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is an ongoing surge for Generative AI to boost service
                desk processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>
                , with its Generative AI-powered conversational AI chatbots, can
                help transform service desks toward ultimate digitalization.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The powerful features of Generative AI in{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  Workativ’s conversational AI
                </a>{" "}
                platform unlock huge opportunities to apply over mundane
                workflows and streamline them efficiently for the ultimate
                service desk management and growth.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What do you mean by service desk transformation?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="GenAI-based service desk transformation"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation">
                  Service desk transformation
                </a>{" "}
                can refer to augmenting existing capabilities or current
                workflows for employee support queries with extended automation.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The use of{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  advanced AI tools and features, such as AI and{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-dummies">
                    Generative AI,
                  </a>
                  can unlock massive opportunities to handle common and unique
                  service desk requests along with pre-defined problems
                  autonomously,
                </span>{" "}
                which substantially leads to service desk transformation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Besides managing routine tasks, service desk transformation
                helps address new challenges and reduces the need to create
                time-consuming workflows for known and unknown scenarios and
                seek agents’ assistance frequently.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is Generative AI for the service desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI is a new subset of Artificial Intelligence or deep
                neural networks built on large language models to act like
                humans and understand NLP queries efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Leveraging billions of parameters helps GenAI multiply search
                functions to understand NLP inputs and create new content,
                texts, images, or sounds, just as humans do.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="GenAI-based service desk transformation"
              />
              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                LLMs allow GenAI to generate human-like responses, demonstrating
                the huge possibilities for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/5-help-desk-tasks-everyone-should-automate">
                  automating service desk responses
                </a>{" "}
                to help solve problems related to routine tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Combining this search capability across its large language
                models, Generative AI increases the accuracy of information
                search better than conventional AI search and improves the&nbsp;
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                  service desk experience.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why do you need Generative AI for service desks?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Service desk queries are repetitive for routine tasks. Employees
                and level-one agents have low productivity rates due to the
                prolonged time needed to address these issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Routine questions involving{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  <a href="https://workativ.com/use-cases/reset-password-automation">
                    password resets
                  </a>{" "}
                  or account unlocks
                </span>{" "}
                emerge every day and deter the normal productivity of employees.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="challenges of typical service desks"
              />
              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                Automation in service desks can manage predefined workflows but
                has lower success rates with cross-functional tasks or unique
                requests.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Existing automation is limited and only capable of managing
                    predefined workflows.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Self-service has limited reach due to its difficulty
                    navigating the ITSM platform.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Most tickets involve repetitive cases, rising backlog
                    volumes, and agents being forced to manage routine and
                    mundane tasks rather than higher-value jobs.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Three days is the maximum time for employees and the service
                    desk agents to complete a routine query.
                  </span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s say your employees can manage password resets for a
                software testing app. However, the problem arises when the
                company application migrates to a new testing app and the
                workflow is not set up.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This can hinder self-service due to limited automation, escalate
                the issue to the level-one agent, and take longer to resolve.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, Generative AI can help in this situation. Other than
                abruptly saying, ‘Please rephrase your query, or resurfacing the
                same dialog cards,’ it can learn and develop cognitive
                intelligence to offer help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For a fundamental level of help, it can share a link to a form
                that users can fill out to gain access.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, Generative AI can directly fetch exact
                information from the company's knowledge base and guide users to
                take appropriate action.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How does Generative AI help the service desk transform?
              </h2>

              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="GenAI-based service desk use cases"
              />
              <p className="font-section-normal-text-testimonials-medium color-black line-height-18 ">
                Generative AI utilizes billions of parameters to multiply
                search, find information, and create new and innovative content.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Generative AI or LLM-based service desk transformation "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With the extended level of natural language understanding for
                massive language training, Generative AI can help with knowledge
                discovery and problem resolution, helping{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  service desks transform
                </span>{" "}
                at large. Let’s draw a comparison between a typical service desk
                and a transformative service desk augmented by Generative AI.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Service desk responses
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Rapid collaboration and communication are needed to resolve
                everyday service desk issues. Employees aim to have meaningful,
                accurate, and fast information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generally, service desks merely handle complex questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Responses can be vague on most occasions. When asked to provide
                a resolution, self-service can generate lengthy articles,
                requiring users to spend hours finding information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The main problem is that the traditional service desk can
                fetch&nbsp;
                <span className="font-section-normal-text-testimonials-medium color-black">
                  keyword-based articles
                </span>{" "}
                regardless of whether they match the context or intent of users'
                input.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As a result, delayed responses can impact service desk MTTR and
                compromise employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Modern service desks with GenAI properties apply patterns and
                algorithms in the large language models to find a match within
                their training data and generate responses. In most cases,&nbsp;
                <a href="https://workativ.com/conversational-ai-platform/blog/the-modern-employee-and-modern-it-help-desk-self-service">
                  Generative AI can surface meaningful responses based on intent
                  and context.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Besides,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  GenAI can summarize,
                </span>{" "}
                which allows it to instantly consolidate long articles and
                complex questions into contextual and concise information to
                resolve a common query, such as password resets, VPN settings,
                etc.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                IT ticket resolution
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Service desks aim to close as many tickets as possible.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, typical service desks can lack understanding and have
                limited ability inside self-service to address tickets faster.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI can instantly surface related information in
                real-time, making self-service efficient in answering common to
                complex questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This perhaps boosts{" "}
                <a href="https://workativ.com/conversational-ai-platform/virtual-agent">
                  5X lower MTTR
                </a>{" "}
                and frees agents to focus on more complex issues. .
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As a result, service desks can manage and resolve IT tickets
                faster.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                End-user experience
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Wait times at typical service desks are quite long. Solving an
                industry service desk issue takes over three days.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                When used with conversational AI and GenAI, MTTR or mean time to
                response comes down merely to minutes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Employees can resolve problems efficiently regardless of remote
                work settings and get a better{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                  service desk experience.{" "}
                </a>
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Higher satisfaction
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Self-service is not without friction; employees have long wait
                times, which impacts their productivity and progress. Both
                service desk users and agents have a growing rate of
                dissatisfaction.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                GenAI improves self-service capabilities with an extended level
                of automation that helps speed up information search and resolve
                problems in real time.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Introducing Workativ’s Generative AI: The ultimate solution for
                service desk transformation
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Service desk transformation through Workativ’s GenAI features"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s Generative AI solution,{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI,
                </a>{" "}
                is cutting-edge technology that can transform your service desk
                functions for employee support within MS Teams and Slack.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By extending the off-the-shelf GPT capabilities, Workativ aims
                to redefine employee support and transform service desks with
                custom answers specific to your domain.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ ensures your employees can easily remove self-service
                friction, as observed in typical service desks, elevating
                employee efficiency, facilitating resilience, and boosting
                employee satisfaction.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Conversational AI platform
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="GenAI-based conversational AI platform on Workativ  
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                How about creating engaging conversations yet meaningful enough
                to resolve workplace queries? Workativ’s{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  conversational AI platform
                </a>{" "}
                combines the powerful LLM to seamlessly integrate NLP
                capabilities and conversational search to help you build
                scalable chatbots in no time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ provides intent detection, context, and entity
                extraction to improve comprehension for simple to complex
                queries and resolve them quickly.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Knowledge AI or RAG for accelerated search results
              </h3>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="RAG search for custom answers on GenAI-based service desk 

                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                can be termed a hybrid NLU or Retrieval Augmented Generation
                (RAG) to help extend the realm of information repository. The
                combination of the world of knowledge, semantic search, and
                authoritative resources from third-party sources enrich
                information veracity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                There is a rare chance that Knowledge AI fetches wrong answers.
                Instead, Knowledge AI helps rapidly identify answers to
                domain-specific questions related to one’s business functions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                At a time, Knowledge AI extends the capability of GPTs designed
                to retrieve answers for known scenarios while also helping
                surface internal service-related answers.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Shared live inbox for service desk agents
              </h3>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="GenAI-based agents’ screen for service desk transformation 


                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A{" "}
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  shared live inbox
                </a>{" "}
                is a real problem solver for agents. Workativ makes it easy for
                employees to get a resolution at the first contact or level 1.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Service desk agents can unify queries on a single screen and
                improve employee request visibility using a shared live inbox
                feature layered with Generative AI features.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Agents can comprehend the priority level of tickets, their
                status, or their progress in real-time, giving them the best
                user experience and enabling them to solve queries steadily.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Additionally, AI suggestions reduce the need to craft messages
                to share with users. The text generator can suggest effective
                recommendations for even novice agents, enabling them to
                coordinate steadily with employees.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Custom app workflows
              </h3>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="App workflow automation on GenAI-based service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s app workflow automation enables multi-step or
                single-step flow for various use cases for your internal
                business processes. The pre-built app integrations allow you to
                add your preferred service desk apps and manage workflows needed
                to streamline operations at scale.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The best thing is to create workflows for various use cases or
                simply allow your bot to fetch information from your knowledge
                bases to answer unique questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The magic of Generative AI can always answer and help resolve
                queries.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What can you do with Workativ’s Generative AI?
              </h2>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="GenAI-based service desk use cases with Workativ"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI is a business desire for organizations to
                transform their service desks. You can leverage Knowledge AI or
                Workativ’s Generative AI to implement domain-specific use cases.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                1. Improved knowledge management for continuous improvement of
                the service desk responses
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI improves knowledge management by allowing employees
                to capture accurate and contextual responses. The RAG approach
                in Knowledge AI enables users to search for information from
                third-party company knowledge resources.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Retrieval Augmented Generation, or RAG, enhances faster
                coordination and collaboration to facilitate problem resolution
                through effective knowledge management.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                2. Self-service automation with zero wait time
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI reduces MTTR for service desks regardless of HR or
                IT support queries and promotes{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-user-support-zero-touch">
                  zero wait time.{" "}
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Employees can handle common to complex queries for password
                resets, VPN settings, account unlocks, software provisions, etc.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                3. GenAI-powered AITSM
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                ITSM can easily transition to a Generative AI-powered ecosystem,
                meaning ITSM becomes known as{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-itsm">
                  AITSM.
                </a>{" "}
                It can have better automation power to automate repetitive
                workflows and provide enhanced reporting and analytics to
                improve service delivery performance.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                4. Hyperautomation for agent support
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Agents can utilize a shared live inbox and AI suggestions to
                speed up resolutions and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/empowering-it-teams-generative-ai-human-expertise">
                  handle more service requests,
                </a>{" "}
                enhancing the employee experience. Also, Generative AI can
                enable instant agent handover when a query seems too tough for
                self-service.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                5. Enhanced cross-functional support
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s Generative AI uses NLP and NLU to facilitate
                cross-functional support and reduces the involvement of human
                agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For onboarding or offboarding-related functions, GenAI can help
                enable end-to-end automation for associated tasks. However,
                there are more use cases where Generative AI can help.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The Advantages of Workativ’s Generative AI
              </h2>
              <img
                src={section_12}
                className="blog_image_top_bt"
                alt="GenAI-based service desk advantages with Workativ  
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The chatbot you build with Workativ’s Generative AI can
                significantly benefit service desk efficiency and user
                experience.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                1. Rapid chatbot development with enhanced automation power
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s conversational AI platform is a no-code platform that
                seamlessly helps you build a{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  service desk chatbot
                </a>{" "}
                you can use within MS Teams or Slack and gain Generative AI
                capabilities to streamline IT or HR support.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                2. Shift-left enablement
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                GenAI and RAG can answer more questions about common and complex
                issues. This reduces dependency on agents from higher tiers and
                promotes self-service efficiently, ultimately saving costs.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                3. SLAs to XLAs transition
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                GenAI capabilities inside the Workativ chatbot address tickets
                and carefully give rich insights to help prevent the recurrence
                of the same requests. Using this capability, service desk
                managers can promote experience-level agreements.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                4. Multilingual support
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s GenAI encompasses multi-language comprehension that
                helps translate any language into preferred languages that users
                understand. Therefore, Workativ helps break the language barrier
                and enables them to use knowledge resources easily.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                5. Increased productivity and efficiency
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ brings ChatGPT-like responses with the power of RAG,
                meaning users can gain speed for information search while
                ensuring accuracy and relevance for their questions and solving
                problems steadily. This helps reduce downtime and increase
                uptime.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                6. Cost efficiency
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s Generative AI improves{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-service-desk-cost-savings">
                  ROI for your service desks.
                </a>{" "}
                It can save agents’ time and help save a significant amount for
                your bottom line that would otherwise be spent across agent
                hiring, training, and development.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How to get started with Workativ’s Generative AI
              </h2>
              <img
                src={section_13}
                className="blog_image_top_bt"
                alt="Steps to getting started with Workativ’s GenAI-based platform  for service desks 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s Generative AI is very simple to integrate LLMs into
                your workflows and gain ChatGPT effectiveness while leveraging
                RAG for custom solutions.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI provides no-code ways to easily integrate LLMs and
                augment your service desk operations.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                1. Create your knowledge articles.
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Examine to find out which{" "}
                <a href="https://workativ.com/features.html">
                  IT or HR support
                </a>{" "}
                scenarios are significant to be streamlined with automation. You
                can create knowledge bases and upload them to the Knowledge AI
                interface.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                2. Prepare custom workflows
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Besides common problems that self-service can solve, you must
                create workflows for unexpected scenarios and enable handover.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                3. Deploy the bot
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Once your knowledge articles are ready and workflows are set up,
                you can integrate them with your bot and deploy it inside MS
                Teams and Slack.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                4. Measure and tweak
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ provides comprehensive data analytics to measure the
                performance of your GenAI chatbots for the service desk.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                5. Train your people
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ's intuitive interface helps users adapt seamlessly and
                utilize its features efficiently. However, initial employee
                training can help your people use the interface to its potential
                and gain significant benefits.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                In addition, Workativ also provides 24/7 support to help you get
                used to its interface and apply GenAI automation for service
                desk cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also, create a feedback loop to look for your people’s reviews.
                This strategy can help you implement changes and improve bot
                interaction.
              </p>
              <h3 className="font-section-sub-header-small-bold color-black">
                Conclusion:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Service desks gain more powerful capabilities by leveraging
                Generative AI, helping them transform at scale.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s Generative AI feature, Knowledge AI, can elevate your
                service desks’ existing capabilities, remove self-service
                friction, offer better insights for improvement, and expedite
                efficiency and productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Today, every IT leader wants to apply GenAI and augment service
                desk automation to streamline service delivery, improve agents’
                efficiency, elevate employee experience, and retain employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ makes it easy to adopt GenAI-powered service desks
                effortlessly and rapidly gain a competitive advantage.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you increase employee support efficiency and user experience,
                Workativ can help you.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To learn more about Workativ’s Generative AI,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What is Generative AI in service desks?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI unleashes the NLP capabilities using LLMs to
                generate new content and extend response automation. This is
                highly effective for customer support and employee experience
                management. Service desks can utilize Generative AI to automate
                repetitive tasks, enable self-service, and augment agents’
                efficiency.
              </p>
              <h3 className="font-section-sub-header-small">
                2. What is service desk transformation powered by Generative AI?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unlike typical service desks with RPA-based capabilities,
                Generative AI drives service desk transformation by enabling
                users to leverage LLMs to improve response automation and
                implement hyper-automation to streamline employee support at
                scale. The service desk transformation can augment resolutions
                and boost agents’ efficiency.
              </p>
              <h3 className="font-section-sub-header-small">
                3. What are the GenAI-based employee support use cases for
                service desks?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can remove friction from existing self-service by applying
                GenAI capabilities to common to complex use cases, such as
                password reset, VPN settings, employee onboarding/offboarding,
                PTO enquiry, reimbursement claims, etc.
              </p>
              <h3 className="font-section-sub-header-small">
                4. Can Workativ’s Knowledge AI handle custom queries?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI encompasses the RAG approach, allowing employees to
                search for information from third-party resources and solve
                problems outside of known scenarios.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What do you mean by service desk transformation?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. What is Generative AI for the service desk?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Why do you need Generative AI for service desks?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. How does Generative AI help the service desk transform?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Introducing Workativ’s Generative AI: The ultimate
                    solution for service desk transformation
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. What can you do with Workativ’s Generative AI?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. The Advantages of Workativ’s Generative AI
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section8"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section8"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section8")}
                  >
                    8. How to get started with Workativ’s Generative AI
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section9"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section9"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section9")}
                  >
                    9. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                The big truth about{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  service desks
                </a>{" "}
                is that they are, in some way, incapable of managing the entire
                workflow needs.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Automation makes things easier, yet it encompasses a traditional
                approach.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Shift-left is ineffective in many scenarios, and SLAs rarely
                transition to XLAs. IT support is reactive rather than
                proactive.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The overall impact is a productivity decline, employee
                dissatisfaction, agent burnout, and attrition.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For IT leaders,
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Generative AI is a boon to transform the existing state of
                  service desks.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The{" "}
                <a href="https://www.nber.org/digest/20236/measuring-productivity-impact-generative-ai">
                  National Bureau of Economic Research (NBER)
                </a>{" "}
                studied productivity gains using Generative AI. They claimed
                that the pre-trained generative transformer (GPT), the
                underlying deep learning technology of Generative AI, can help
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  boost customer support worker productivity by 14%.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As per the study, if any role needs information to do a job,
                Generative AI can speed up their ability to find information
                quickly and gain success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is an ongoing surge for Generative AI to boost service
                desk processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>
                , with its Generative AI-powered conversational AI chatbots, can
                help transform service desks toward ultimate digitalization.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The powerful features of Generative AI in{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  Workativ’s conversational AI
                </a>{" "}
                platform unlock huge opportunities to apply over mundane
                workflows and streamline them efficiently for the ultimate
                service desk management and growth.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What do you mean by service desk transformation?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="GenAI-based service desk transformation"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation">
                  Service desk transformation
                </a>{" "}
                can refer to augmenting existing capabilities or current
                workflows for employee support queries with extended automation.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The use of{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  advanced AI tools and features, such as AI and{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-dummies">
                    Generative AI,
                  </a>
                  can unlock massive opportunities to handle common and unique
                  service desk requests along with pre-defined problems
                  autonomously,
                </span>{" "}
                which substantially leads to service desk transformation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Besides managing routine tasks, service desk transformation
                helps address new challenges and reduces the need to create
                time-consuming workflows for known and unknown scenarios and
                seek agents’ assistance frequently.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is Generative AI for the service desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI is a new subset of Artificial Intelligence or deep
                neural networks built on large language models to act like
                humans and understand NLP queries efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Leveraging billions of parameters helps GenAI multiply search
                functions to understand NLP inputs and create new content,
                texts, images, or sounds, just as humans do.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="GenAI-based service desk transformation"
              />
              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                LLMs allow GenAI to generate human-like responses, demonstrating
                the huge possibilities for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/5-help-desk-tasks-everyone-should-automate">
                  automating service desk responses
                </a>{" "}
                to help solve problems related to routine tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Combining this search capability across its large language
                models, Generative AI increases the accuracy of information
                search better than conventional AI search and improves the&nbsp;
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                  service desk experience.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why do you need Generative AI for service desks?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Service desk queries are repetitive for routine tasks. Employees
                and level-one agents have low productivity rates due to the
                prolonged time needed to address these issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Routine questions involving{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  <a href="https://workativ.com/use-cases/reset-password-automation">
                    password resets
                  </a>{" "}
                  or account unlocks
                </span>{" "}
                emerge every day and deter the normal productivity of employees.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="challenges of typical service desks"
              />
              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                Automation in service desks can manage predefined workflows but
                has lower success rates with cross-functional tasks or unique
                requests.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Existing automation is limited and only capable of managing
                    predefined workflows.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Self-service has limited reach due to its difficulty
                    navigating the ITSM platform.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Most tickets involve repetitive cases, rising backlog
                    volumes, and agents being forced to manage routine and
                    mundane tasks rather than higher-value jobs.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Three days is the maximum time for employees and the service
                    desk agents to complete a routine query.
                  </span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s say your employees can manage password resets for a
                software testing app. However, the problem arises when the
                company application migrates to a new testing app and the
                workflow is not set up.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This can hinder self-service due to limited automation, escalate
                the issue to the level-one agent, and take longer to resolve.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, Generative AI can help in this situation. Other than
                abruptly saying, ‘Please rephrase your query, or resurfacing the
                same dialog cards,’ it can learn and develop cognitive
                intelligence to offer help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For a fundamental level of help, it can share a link to a form
                that users can fill out to gain access.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, Generative AI can directly fetch exact
                information from the company's knowledge base and guide users to
                take appropriate action.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How does Generative AI help the service desk transform?
              </h2>

              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="GenAI-based service desk use cases"
              />
              <p className="font-section-normal-text-testimonials-medium color-black line-height-18 ">
                Generative AI utilizes billions of parameters to multiply
                search, find information, and create new and innovative content.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Generative AI or LLM-based service desk transformation "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With the extended level of natural language understanding for
                massive language training, Generative AI can help with knowledge
                discovery and problem resolution, helping{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  service desks transform
                </span>{" "}
                at large. Let’s draw a comparison between a typical service desk
                and a transformative service desk augmented by Generative AI.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Service desk responses
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Rapid collaboration and communication are needed to resolve
                everyday service desk issues. Employees aim to have meaningful,
                accurate, and fast information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generally, service desks merely handle complex questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Responses can be vague on most occasions. When asked to provide
                a resolution, self-service can generate lengthy articles,
                requiring users to spend hours finding information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The main problem is that the traditional service desk can
                fetch&nbsp;
                <span className="font-section-normal-text-testimonials-medium color-black">
                  keyword-based articles
                </span>{" "}
                regardless of whether they match the context or intent of users'
                input.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As a result, delayed responses can impact service desk MTTR and
                compromise employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Modern service desks with GenAI properties apply patterns and
                algorithms in the large language models to find a match within
                their training data and generate responses. In most cases,&nbsp;
                <a href="https://workativ.com/conversational-ai-platform/blog/the-modern-employee-and-modern-it-help-desk-self-service">
                  Generative AI can surface meaningful responses based on intent
                  and context.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Besides,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  GenAI can summarize,
                </span>{" "}
                which allows it to instantly consolidate long articles and
                complex questions into contextual and concise information to
                resolve a common query, such as password resets, VPN settings,
                etc.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                IT ticket resolution
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Service desks aim to close as many tickets as possible.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, typical service desks can lack understanding and have
                limited ability inside self-service to address tickets faster.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI can instantly surface related information in
                real-time, making self-service efficient in answering common to
                complex questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This perhaps boosts{" "}
                <a href="https://workativ.com/conversational-ai-platform/virtual-agent">
                  5X lower MTTR
                </a>{" "}
                and frees agents to focus on more complex issues. .
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As a result, service desks can manage and resolve IT tickets
                faster.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                End-user experience
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Wait times at typical service desks are quite long. Solving an
                industry service desk issue takes over three days.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                When used with conversational AI and GenAI, MTTR or mean time to
                response comes down merely to minutes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Employees can resolve problems efficiently regardless of remote
                work settings and get a better{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                  service desk experience.{" "}
                </a>
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Higher satisfaction
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Self-service is not without friction; employees have long wait
                times, which impacts their productivity and progress. Both
                service desk users and agents have a growing rate of
                dissatisfaction.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                GenAI improves self-service capabilities with an extended level
                of automation that helps speed up information search and resolve
                problems in real time.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Introducing Workativ’s Generative AI: The ultimate solution for
                service desk transformation
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Service desk transformation through Workativ’s GenAI features"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s Generative AI solution,{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI,
                </a>{" "}
                is cutting-edge technology that can transform your service desk
                functions for employee support within MS Teams and Slack.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By extending the off-the-shelf GPT capabilities, Workativ aims
                to redefine employee support and transform service desks with
                custom answers specific to your domain.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ ensures your employees can easily remove self-service
                friction, as observed in typical service desks, elevating
                employee efficiency, facilitating resilience, and boosting
                employee satisfaction.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Conversational AI platform
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="GenAI-based conversational AI platform on Workativ  
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                How about creating engaging conversations yet meaningful enough
                to resolve workplace queries? Workativ’s{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  conversational AI platform
                </a>{" "}
                combines the powerful LLM to seamlessly integrate NLP
                capabilities and conversational search to help you build
                scalable chatbots in no time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ provides intent detection, context, and entity
                extraction to improve comprehension for simple to complex
                queries and resolve them quickly.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Knowledge AI or RAG for accelerated search results
              </h3>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="RAG search for custom answers on GenAI-based service desk 

                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                can be termed a hybrid NLU or Retrieval Augmented Generation
                (RAG) to help extend the realm of information repository. The
                combination of the world of knowledge, semantic search, and
                authoritative resources from third-party sources enrich
                information veracity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                There is a rare chance that Knowledge AI fetches wrong answers.
                Instead, Knowledge AI helps rapidly identify answers to
                domain-specific questions related to one’s business functions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                At a time, Knowledge AI extends the capability of GPTs designed
                to retrieve answers for known scenarios while also helping
                surface internal service-related answers.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Shared live inbox for service desk agents
              </h3>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="GenAI-based agents’ screen for service desk transformation 


                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A{" "}
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  shared live inbox
                </a>{" "}
                is a real problem solver for agents. Workativ makes it easy for
                employees to get a resolution at the first contact or level 1.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Service desk agents can unify queries on a single screen and
                improve employee request visibility using a shared live inbox
                feature layered with Generative AI features.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Agents can comprehend the priority level of tickets, their
                status, or their progress in real-time, giving them the best
                user experience and enabling them to solve queries steadily.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Additionally, AI suggestions reduce the need to craft messages
                to share with users. The text generator can suggest effective
                recommendations for even novice agents, enabling them to
                coordinate steadily with employees.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Custom app workflows
              </h3>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="App workflow automation on GenAI-based service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s app workflow automation enables multi-step or
                single-step flow for various use cases for your internal
                business processes. The pre-built app integrations allow you to
                add your preferred service desk apps and manage workflows needed
                to streamline operations at scale.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The best thing is to create workflows for various use cases or
                simply allow your bot to fetch information from your knowledge
                bases to answer unique questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The magic of Generative AI can always answer and help resolve
                queries.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What can you do with Workativ’s Generative AI?
              </h2>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="GenAI-based service desk use cases with Workativ"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI is a business desire for organizations to
                transform their service desks. You can leverage Knowledge AI or
                Workativ’s Generative AI to implement domain-specific use cases.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                1. Improved knowledge management for continuous improvement of
                the service desk responses
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI improves knowledge management by allowing employees
                to capture accurate and contextual responses. The RAG approach
                in Knowledge AI enables users to search for information from
                third-party company knowledge resources.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Retrieval Augmented Generation, or RAG, enhances faster
                coordination and collaboration to facilitate problem resolution
                through effective knowledge management.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                2. Self-service automation with zero wait time
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI reduces MTTR for service desks regardless of HR or
                IT support queries and promotes{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-user-support-zero-touch">
                  zero wait time.{" "}
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Employees can handle common to complex queries for password
                resets, VPN settings, account unlocks, software provisions, etc.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                3. GenAI-powered AITSM
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                ITSM can easily transition to a Generative AI-powered ecosystem,
                meaning ITSM becomes known as{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-itsm">
                  AITSM.
                </a>{" "}
                It can have better automation power to automate repetitive
                workflows and provide enhanced reporting and analytics to
                improve service delivery performance.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                4. Hyperautomation for agent support
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Agents can utilize a shared live inbox and AI suggestions to
                speed up resolutions and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/empowering-it-teams-generative-ai-human-expertise">
                  handle more service requests,
                </a>{" "}
                enhancing the employee experience. Also, Generative AI can
                enable instant agent handover when a query seems too tough for
                self-service.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                5. Enhanced cross-functional support
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s Generative AI uses NLP and NLU to facilitate
                cross-functional support and reduces the involvement of human
                agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For onboarding or offboarding-related functions, GenAI can help
                enable end-to-end automation for associated tasks. However,
                there are more use cases where Generative AI can help.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The Advantages of Workativ’s Generative AI
              </h2>
              <img
                src={section_12}
                className="blog_image_top_bt"
                alt="GenAI-based service desk advantages with Workativ  
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The chatbot you build with Workativ’s Generative AI can
                significantly benefit service desk efficiency and user
                experience.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                1. Rapid chatbot development with enhanced automation power
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s conversational AI platform is a no-code platform that
                seamlessly helps you build a{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  service desk chatbot
                </a>{" "}
                you can use within MS Teams or Slack and gain Generative AI
                capabilities to streamline IT or HR support.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                2. Shift-left enablement
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                GenAI and RAG can answer more questions about common and complex
                issues. This reduces dependency on agents from higher tiers and
                promotes self-service efficiently, ultimately saving costs.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                3. SLAs to XLAs transition
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                GenAI capabilities inside the Workativ chatbot address tickets
                and carefully give rich insights to help prevent the recurrence
                of the same requests. Using this capability, service desk
                managers can promote experience-level agreements.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                4. Multilingual support
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s GenAI encompasses multi-language comprehension that
                helps translate any language into preferred languages that users
                understand. Therefore, Workativ helps break the language barrier
                and enables them to use knowledge resources easily.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                5. Increased productivity and efficiency
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ brings ChatGPT-like responses with the power of RAG,
                meaning users can gain speed for information search while
                ensuring accuracy and relevance for their questions and solving
                problems steadily. This helps reduce downtime and increase
                uptime.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                6. Cost efficiency
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s Generative AI improves{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-service-desk-cost-savings">
                  ROI for your service desks.
                </a>{" "}
                It can save agents’ time and help save a significant amount for
                your bottom line that would otherwise be spent across agent
                hiring, training, and development.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How to get started with Workativ’s Generative AI
              </h2>
              <img
                src={section_13}
                className="blog_image_top_bt"
                alt="Steps to getting started with Workativ’s GenAI-based platform  for service desks 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s Generative AI is very simple to integrate LLMs into
                your workflows and gain ChatGPT effectiveness while leveraging
                RAG for custom solutions.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI provides no-code ways to easily integrate LLMs and
                augment your service desk operations.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                1. Create your knowledge articles.
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Examine to find out which{" "}
                <a href="https://workativ.com/features.html">
                  IT or HR support
                </a>{" "}
                scenarios are significant to be streamlined with automation. You
                can create knowledge bases and upload them to the Knowledge AI
                interface.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                2. Prepare custom workflows
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Besides common problems that self-service can solve, you must
                create workflows for unexpected scenarios and enable handover.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                3. Deploy the bot
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Once your knowledge articles are ready and workflows are set up,
                you can integrate them with your bot and deploy it inside MS
                Teams and Slack.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                4. Measure and tweak
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ provides comprehensive data analytics to measure the
                performance of your GenAI chatbots for the service desk.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                5. Train your people
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ's intuitive interface helps users adapt seamlessly and
                utilize its features efficiently. However, initial employee
                training can help your people use the interface to its potential
                and gain significant benefits.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                In addition, Workativ also provides 24/7 support to help you get
                used to its interface and apply GenAI automation for service
                desk cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also, create a feedback loop to look for your people’s reviews.
                This strategy can help you implement changes and improve bot
                interaction.
              </p>
              <h3 className="font-section-sub-header-small-bold color-black">
                Conclusion:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Service desks gain more powerful capabilities by leveraging
                Generative AI, helping them transform at scale.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s Generative AI feature, Knowledge AI, can elevate your
                service desks’ existing capabilities, remove self-service
                friction, offer better insights for improvement, and expedite
                efficiency and productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Today, every IT leader wants to apply GenAI and augment service
                desk automation to streamline service delivery, improve agents’
                efficiency, elevate employee experience, and retain employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ makes it easy to adopt GenAI-powered service desks
                effortlessly and rapidly gain a competitive advantage.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you increase employee support efficiency and user experience,
                Workativ can help you.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To learn more about Workativ’s Generative AI,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What is Generative AI in service desks?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI unleashes the NLP capabilities using LLMs to
                generate new content and extend response automation. This is
                highly effective for customer support and employee experience
                management. Service desks can utilize Generative AI to automate
                repetitive tasks, enable self-service, and augment agents’
                efficiency.
              </p>
              <h3 className="font-section-sub-header-small">
                2. What is service desk transformation powered by Generative AI?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unlike typical service desks with RPA-based capabilities,
                Generative AI drives service desk transformation by enabling
                users to leverage LLMs to improve response automation and
                implement hyper-automation to streamline employee support at
                scale. The service desk transformation can augment resolutions
                and boost agents’ efficiency.
              </p>
              <h3 className="font-section-sub-header-small">
                3. What are the GenAI-based employee support use cases for
                service desks?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can remove friction from existing self-service by applying
                GenAI capabilities to common to complex use cases, such as
                password reset, VPN settings, employee onboarding/offboarding,
                PTO enquiry, reimbursement claims, etc.
              </p>
              <h3 className="font-section-sub-header-small">
                4. Can Workativ’s Knowledge AI handle custom queries?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI encompasses the RAG approach, allowing employees to
                search for information from third-party resources and solve
                problems outside of known scenarios.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai"
              className="font-section-normal-text-testimonials"
            >
              Generative AI Service Desks: Catalysts for Successful Digital
              Transformation Initiatives
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk"
              className="font-section-normal-text-testimonials"
            >
              Reactive to Proactive: Leveraging Generative AI for Smarter
              Employee Service Desks
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/leverage-generative-ai-service-desk-cto"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              The CTO's Secret Weapon: Leveraging Generative AI Service Desks
              for Success
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
